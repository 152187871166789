export default {
  clickedButtonEditField: 'clickedButton_editField',
  clickedButtonCreateField: 'clickedButton_createField',
  clickedButtonSeeCatalog: 'clickedButton_seeCatalog',
  clickedButtonPlanFungicide: 'clickedButton_planFungicide',
  clickedButtonCreateNote: 'clickedButton_createNote',
  clickedButtonSeeAllFields: 'clickedButton_seeAllFields',
  clickedButtonSeeSowing: 'clickedButton_seeSowing',
  clickedButtonDeleteField: 'clickedButton_deleteField',
  clickedButtonDeleteSowing: 'clickedButton_deleteSowing',
}
