import Module from '@/modules/home/Module.vue'
import Home from '@/modules/home/views/Home.vue'

const routes = [
  {
    path: '/my_farm',
    component: Module,
    children: [
      {
        path: '/',
        component: Home,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
