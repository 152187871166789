<template>
  <v-container class="d-flex flex-column item-list pa-0">
    <v-row
      no-gutters
      justify="space-between"
      align="center"
      class="item-list__heading"
    >
      <h2 class="heading__title">
        {{ $t(title) }}<span class="title__details">({{ items.length }})</span>
      </h2>
      <df-button variant="text" @click="openItemModal">
        <p class="heading__button">
          {{ $t('Home.item_list.see_all') }}
        </p>
      </df-button>
    </v-row>
    <loader data-id="loader" v-if="loading" :show-loading-text="false" />
    <v-slide-group v-else class="list-slider__tablet" show-arrows>
      <v-list-item
        v-for="item in items"
        :key="item.id"
        class="rounded slider__item"
      >
        <item-card :item="item" :name="name" />
      </v-list-item>
    </v-slide-group>
    <div v-if="!loading" class="list-slider__mobile">
      <item-card
        v-for="item in items.slice(0, 5)"
        :key="item.id"
        :item="item"
        :name="name"
      />
    </div>
    <modal-talhoes
      v-if="isOpenFieldsModal"
      :farm="currentFarm"
      @close-modal="closeItemModal"
    />
  </v-container>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import ItemCard from '@/modules/home/components/ItemCard.vue'
import Loader from '@/components/Loaders/Loader.vue'
import ModalTalhoes from '@/pages/fazendas/ModalTalhoes.vue'
import { logEvent, events } from '@/services/analytics'
import { mapGetters } from 'vuex'

export default {
  name: 'ItemList',

  components: {
    DfButton,
    ItemCard,
    Loader,
    ModalTalhoes,
  },

  props: {
    items: {
      default: () => [],
      type: Array,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'field',
      type: String,
    },
  },

  data() {
    return {
      isOpenFieldsModal: false,
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),
    title() {
      const itemTitles = {
        field: 'Home.item_list.fields',
        sowing: 'Home.item_list.active_sowings',
      }
      return itemTitles[this.name]
    },
  },

  methods: {
    closeItemModal() {
      this.isOpenFieldsModal = false
    },
    openItemModal() {
      if (this.name === 'field') {
        logEvent(events.homeModule.clickedButtonSeeAllFields)
        this.isOpenFieldsModal = true
      } else if (this.name === 'sowing') {
        logEvent(events.homeModule.clickedButtonSeeSowing)
        this.$router.push({
          path: '/sowings',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-list {
  gap: 16px;

  .item-list__heading {
    padding-right: 82px;

    @include d(m) {
      padding-right: 0px;
    }
    .heading__title {
      color: #071505;
      font-family: 'Rubik';
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;

      &:first-letter {
        text-transform: capitalize;
      }
      .title__details {
        margin-left: 4px;
        color: #5b6459;
        font-weight: 400;
      }
    }
    .heading__button {
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #39af49;

      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  .list-slider__tablet {
    display: flex;

    @include d(m) {
      display: none;
    }
    .slider__item {
      padding: 0px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .list-slider__mobile {
    display: none;

    @include d(m) {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
::v-deep .v-slide-group__next {
  display: block !important;
  position: absolute;
  top: -52px;
  right: 0px;
  height: 34px;
  min-width: 34px;
  border-radius: 999999px;

  &:hover {
    background-color: #e6e9e6;
  }

  & .v-icon {
    right: -11px;
    top: 4px;
  }
}
::v-deep .v-slide-group__prev {
  display: block !important;
  position: absolute;
  top: -52px;
  right: 42px;
  height: 34px;
  min-width: 34px;
  border-radius: 999999px;

  &:hover {
    background-color: #e6e9e6;
  }

  & .v-icon {
    right: -9px;
    top: 4px;
  }
}
::v-deep .v-slide-group__prev .v-icon,
::v-deep .v-slide-group__next .v-icon {
  height: 14px;
  width: 14px;
}
::v-deep .v-slide-group__prev .v-icon:not(:disabled),
::v-deep .v-slide-group__next .v-icon:not(:disabled) {
  color: #39af49;
}
::v-deep .v-slide-group__content {
  flex: none;
}
</style>
