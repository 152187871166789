<script>
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HeaderNoFarms',

  mixins: [PermissionMixin],

  components: {
    ModalFazenda,
  },

  data() {
    return {
      dialogModalFarm: false,
    }
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
  },

  methods: {
    ...mapActions('farms', ['fetchFarms', 'firstFarmLoad', 'hasNoFarms']),
    createFarm() {
      this.dialogModalFarm = true
    },
    async reloadFarmsList() {
      if (this.hasNoFarms) {
        await this.firstFarmLoad()
      } else {
        await this.fetchFarms()
      }
      this.dialogModalFarm = false
    },
  },
}
</script>

<template>
  <div class="distance-navbar">
    <v-card>
      <v-img
        src="img/header_home_page_no_farms.webp"
        class="add-card-home-page"
        id="shadowImage"
      >
        <v-row class="mb-6" justify="center" no-gutters>
          <v-col cols="12" class="add-col-hello">
            <v-toolbar-title class="add-col-hello-user">
              {{ $t('Home.header.hello_user', [currentUser.name]) }}
              Digifarmz,</v-toolbar-title
            >
            <v-toolbar-title
              class="add-description-no-farms"
              v-html="$t('fazenda.welcome_no_farms')"
            >
            </v-toolbar-title>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
    <v-row justify="center" class="add-farm-container">
      <v-col cols="12" md="4" sm="12" xl="3">
        <v-card @click="createFarm" class="add-card-create-farm">
          <v-row justify="center">
            <v-col md="12" sm="4" class="add-icon-create-farm">
              <v-img src="img/tractor-icon.png" class="add-image-tractor" />
            </v-col>
            <v-col md="12" sm="8">
              <div class="add-description-action">
                <v-card-text class="add-title-main">{{
                  $t('fazenda.create_farm')
                }}</v-card-text>
              </div>

              <div class="add-subtitle-main">
                <v-card-subtitle
                  class="add-farm-subtitle"
                  v-html="$t('fazenda.manual_create_farm')"
                >
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="12" xl="3" v-show="false">
        <v-card
          link
          to="/integracoes/fieldview"
          class="add-card-import-field-view"
        >
          <v-row justify="center">
            <v-col md="12" sm="4" class="add-icon-field-view">
              <v-img
                src="img/FieldView-icon.png"
                class="add-size-icon-field-view"
              />
            </v-col>
            <v-col md="12" sm="8">
              <div class="add-col-hello">
                <v-card-text
                  class="add-title-main"
                  v-html="$t('Home.header.fieldview_import')"
                ></v-card-text>
              </div>
              <div class="add-col-hello">
                <v-card-subtitle
                  class="add-farm-subtitle"
                  v-html="$t('Home.header.conditional_import_farms_and_plots')"
                >
                </v-card-subtitle>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <modal-fazenda
      v-if="dialogModalFarm"
      :dialog="dialogModalFarm"
      :farm-id="currentFarmId"
      @close="reloadFarmsList"
    />
  </div>
</template>

<style scoped>
.location-state,
.add-col-hello-user {
  color: #62cc70;
}

.add-description-no-farms,
.add-title-main,
.add-col-hello-user {
  font-family: Rubik;
}

.location-state {
  margin: 54px 0px 0px -55px;
}
.add-card-home-page {
  border-radius: 8px;
}
.add-col-hello {
  text-align: center;
}
.add-description-no-farms {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
}
.add-col-hello-user {
  margin-top: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.add-card-create-farm {
  padding-top: 21px;
  padding-bottom: 28px;
}
.add-image-tractor {
  width: 92px;
  margin-inline: auto;
}
.add-farm-container {
  margin: -41px 100px 0px 100px;
}
.add-farm-subtitle {
  margin: -28px 0px 0px 0px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.add-description-action {
  text-align: center;
}
.add-space-title {
  margin-top: -7px;
}
.add-title-main {
  margin-top: -7px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
.add-subtitle-main {
  text-align: center;
}
.add-size-icon-folder {
  width: 81px;
  margin-inline: auto;
}
.add-size-icon-field-view {
  width: 92px;
  margin-inline: auto;
}
.add-card-import-field-view {
  padding-top: 4px;
  padding-bottom: 8px;
}
.add-icon-field-view {
  margin-top: 20px;
}
@media (min-width: 1904px) {
  .add-col-hello-user {
    margin-top: 100px;
    font-size: 20px;
    line-height: 26px;
  }
  .add-description-no-farms {
    font-size: 34px;
  }
}

@media (max-width: 960px) {
  .distance-navbar {
    margin-top: -16px;
  }
  .add-farm-container {
    margin: -41px 0 0 0;
  }
  .add-description-no-farms {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }
  .add-col-hello-user {
    font-weight: 400;
    font-size: 10px;
    line-height: 24px;
  }
  .add-space-title {
    margin-top: 16px;
  }

  @media (max-width: 780px) {
    .add-farm-container {
      margin: -30px 0 0 0;
    }
    .add-col-hello-user {
      margin-top: 26px;
    }
    .add-title-main {
      text-align: initial;
    }
    .add-subtitle-main {
      text-align: initial;
    }
    .add-farm-subtitle {
      text-align: initial;
    }
  }

  @media (max-width: 440px) {
    .add-icon-create-farm {
      margin-right: -70px;
    }
    .add-icon-field-view {
      margin-top: 11px;
      margin-right: -70px;
    }
  }
}
</style>
