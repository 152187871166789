<template>
  <div v-if="currentFarm" class="home-header">
    <df-page-header>
      <template #title-section>
        <v-row no-gutters>
          <v-col>
            <v-chip
              v-if="!currentFarm.enabled"
              color="#9e9e9e"
              small
              class="white--text mb-2"
            >
              {{ $t('she_disabled') }}
            </v-chip>
            <df-header-title
              :subtitle="$t('Home.header.hello_user', [currentUser.name])"
              :title="currentFarm.name"
            />
          </v-col>
        </v-row>
      </template>
      <template #infos>
        <div class="df-flex-l header__infos">
          <div class="df-flex-sm align-baseline">
            <v-icon>mdi-map-marker</v-icon>
            <div class="d-flex df-flex-col">
              <h3 class="info__header">{{ $t('localization') }}</h3>
              <p class="info__text">
                {{ currentFarm.location.city.name }} -
                {{ currentFarm.location.state.acronym }}
              </p>
            </div>
          </div>
          <div class="df-flex-sm align-baseline">
            <v-icon>mdi-vector-square</v-icon>
            <div class="d-flex df-flex-col">
              <h3 class="info__header">{{ $t('Home.header.sown_area') }}</h3>
              <p class="info__text">
                {{ area }}
                {{ $unitMeasures.area[$currentLanguage()] }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template #actions>
        <df-button
          v-if="currentFarm.enabled"
          class="actions__farm"
          variant="text"
          @click="openModalFarms"
        >
          {{ $t('Home.header.edit_farm') }}
        </df-button>
        <v-btn
          v-else
          color="#00000090"
          class="white--text"
          @click="showEnableConfirmation"
        >
          <p class="mr-2 d-sm-block d-none">
            {{ $t('Home.header.enable_farm') }}
          </p>
          <v-icon small class="action-icon__disabled">mdi-toggle-switch</v-icon>
        </v-btn>
      </template>
    </df-page-header>
    <modal-fazenda
      v-if="dialogModalFarm"
      :dialog="dialogModalFarm"
      :farm-id="currentFarmId"
      @close="reloadFarmsList"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfHeaderTitle from '@/lib/components/Header/DfHeaderTitle.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import ModalFazenda from '@/pages/fazendas/ModalFazenda.vue'
import { convertToTargetUnit } from '@/components/Form/converter'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeHeader',

  data() {
    return {
      dialogModalFarm: false,
    }
  },

  components: {
    DfButton,
    DfHeaderTitle,
    DfPageHeader,
    ModalFazenda,
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('farms', ['currentFarm', 'currentFarmId']),
    area() {
      return convertToTargetUnit(this.currentFarm.total_area, 'area')
    },
  },

  methods: {
    ...mapActions('farms', ['fetchFarms', 'enableDisableFarm']),
    openModalFarms() {
      this.dialogModalFarm = true
    },
    async reloadFarmsList() {
      this.dialogModalFarm = false
      await this.fetchFarms(this.$route?.query?.fazenda_id)
    },
    async showEnableConfirmation() {
      await this.enableDisableFarm({
        farmId: this.currentFarmId,
      })

      this.$root.$emit('notify', 'success', this.$t('fazenda.enable.success'))
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.home-header {
  .header__infos {
    flex: 0 0 auto;

    .v-icon {
      font-size: 12px;
      color: #1a2b46;
    }
    .info__header {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      text-transform: uppercase;
    }
    .info__text {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
::v-deep .actions__farm .v-btn__content .text-personalized {
  color: #39af49 !important;
}
</style>
